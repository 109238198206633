import React from "react";
import { navigate } from "@reach/router";

import { Button, KIND } from "baseui/button";
import { ChevronLeft } from "baseui/icon";

const BackButton = ({ title }) => {
  return (
    <Button
      onClick={() => navigate(-1)}
      startEnhancer={<ChevronLeft size={24} />}
      kind={KIND.secondary}
    >
      {title}
    </Button>
  );
};

export default BackButton;
